import React from "react";
import { Table } from "reactstrap";
import "../../assets/styles/App.css";
import Sidebar from "./Sidebar";
import AppNavbar from "./AppNavbar";
import CustomModal from "./CustomModal";
import { backendBaseUrl } from "../../config";
import {
  getSites,
  getAdminsForOrg,
  getUser,
  serviceDeleteUser
} from "../../constants/endpoints";
import {
  getAuthenticatedData,
  deleteAuthenticatedData
} from "../../utils/async";
import { Button } from "react-bootstrap";
import { convertEpochToDate } from "../../utils/date";
// import { Link } from "react-router-dom";
import { roles } from "../../constants/roles";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

export default class ServiceAdmins extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      userResults: getAuthenticatedData(backendBaseUrl + getUser),
      admins: null,
      sites: [],
      currentUser: {},
      deleteButtonDisabled: false,
      showModal: false,
      showAddAccountModal: false
    };
    this.closeModal = this.closeModal.bind(this);
    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
    this.openAddAccountModal = this.openAddAccountModal.bind(this);
    this.toggleAddAdminAccountModal = this.toggleAddAdminAccountModal.bind(
      this
    );
    this.resetList = this.resetList.bind(this);
  }

  componentDidMount() {
    getAuthenticatedData(backendBaseUrl + getSites).then(
      result => {
        this.setState({
          sites: result?.data
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
    getAuthenticatedData(
      backendBaseUrl + getAdminsForOrg + "/" + this?.props?.location?.state?.id
    ).then(
      result => {
        this.getDates(result?.data);
        this.setState({
          admins: result?.data,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
    // this.state.userResults.then(
    //   result => {
    //     this.setState({ isLoaded: true });
    //     if (result?.data?.roles?.includes("USER_ADMIN")) {
    //       this.resetList();
    //     }
    //   },
    //   () => {
    //     this.setState({ isLoaded: true });
    //   }
    // );
  }

  toggleAddAdminAccountModal() {
    this.setState({
      showAddAccountModal: !this.state.showAddAccountModal
    });
  }

  resetList() {
    getAuthenticatedData(
      backendBaseUrl + getAdminsForOrg + "/" + this?.props?.location?.state?.id
    ).then(
      result => {
        this.getDates(result?.data);
        this.setState({
          admins: result?.data,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  handleDeleteButtonClick() {
    this.setState({ deleteButtonDisabled: true });
    deleteAuthenticatedData(
      backendBaseUrl + serviceDeleteUser + "/" + this.state.currentUser.userId,
      {}
    ).then(result => {
      if (result.status === 400) {
        toast.error("This account could not be deleted.");
      } else if (result.status === 200) {
        toast.success("Account successfully deleted");
      } else {
        toast.error("Something went wrong.");
      }
      this.setState({ deleteButtonDisabled: false, showModal: false });
      this.resetList();
    });
  }

  openAddAccountModal() {
    this.setState({ showAddAccountModal: true });
  }

  openModal(user) {
    this.setState({ showModal: true, currentUser: user });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  getDates(items) {
    let dates = items?.map?.(item => convertEpochToDate(item.lastLoginTime));
    this.setState({ dates });
  }

  render() {
    const { isLoaded, admins } = this.state;
    if (!isLoaded) {
      return <div className="loading"></div>;
    } else {
      return (
        <div
          className={
            this.state.showPopup || this.state.showModal
              ? "prevent-scrolling"
              : ""
          }
        >
          <AppNavbar />
          <div className="row">
            <div className="col-md-2 sidebar">
              <Sidebar from="2" results={this.state.userResults} />
            </div>
            <div className="col-md-10 sidepadding">
              <div className="row rowmargin abovetable custom-flex-wrapper">
                <Link
                  to={{ pathname: "/service-dashboard" }}
                  className="back-link"
                >
                  &lt; Back
                </Link>
                <div>
                  <Button
                    className="addaccount"
                    onClick={this.openAddAccountModal}
                  >
                    Add Admin Account
                  </Button>
                  {this.state.showAddAccountModal ? (
                    <CustomModal
                      text='Click "Close Button" to hide popup'
                      closePopup={this.toggleAddAdminAccountModal}
                      resetList={this.resetList}
                      makeAdmin={true}
                      orgId={this?.props?.location?.state?.id}
                    />
                  ) : null}
                </div>
              </div>
              {(() => {
                if (!admins || admins.length === 0) {
                  return (
                    <div className="table-error">
                      <p>There are no results to display</p>
                    </div>
                  );
                } else {
                  return (
                    <div className="table-container">
                      <Table className="tablebox">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Site</th>
                            <th>Email ID</th>
                            <th>Registration Number</th>
                            <th>Role</th>
                            <th>Status</th>
                            <th>Last Login</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {admins?.map?.((item, index) => (
                            <tr key={item.userId}>
                              <td id={"dName" + item.userId}>
                                {item.firstName} {item.lastName}
                              </td>
                              <td id={"dName" + item.userId}>
                                {item.siteName}
                              </td>
                              <td id={"dEmail" + item.userId}>{item.email}</td>
                              <td id={"userType" + item.userId}>
                                {item.employeeId}
                              </td>
                              <td id={"id" + item.userId}>
                                {item.roles
                                  .map(e => roles[e])
                                  .reduce((acc, val) => `${acc} / ${val}`)}
                              </td>
                              <td id={"status" + item.userId}>
                                {item.accountStatus}
                              </td>
                              <td id={"created_at" + item.userId}>
                                {this.state.dates?.[index]}
                              </td>
                              <td className="tablemodal">
                                <a
                                  id={item.userId}
                                  className="editbtn delete-link"
                                  onClick={() => this.openModal(item)}
                                >
                                  Delete
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  );
                }
              })()}
            </div>
          </div>
          {this.state.showModal && (
            <div className="custommodal">
              <div className="custommodaldeleteuser">
                <form className="loginpad" onSubmit={this.handleSubmitEdit}>
                  <h6 className="modal-heading">Delete Account</h6>
                  <Button
                    // eslint-disable-next-line react/prop-types
                    onClick={this.closeModal}
                    className="cancle"
                  >
                    {" "}
                    X{" "}
                  </Button>
                  <hr className="hrpadding" />
                  <p className="delete-user-admin-text">
                    Are you sure you want to delete{" "}
                    {this.state.currentUser.firstName +
                      " " +
                      this.state.currentUser.lastName}
                    ?
                  </p>
                  <Button
                    onClick={this.handleDeleteButtonClick}
                    disabled={this.state.deleteButtonDisabled}
                    className={"loginbtn2 edit-account-btn warning fill"}
                  >
                    {this.state.deleteButtonDisabled ? (
                      <img
                        src={require("../../assets/images/warning-button-loading.gif")}
                      />
                    ) : (
                      <>Delete</>
                    )}
                  </Button>
                </form>
              </div>
            </div>
          )}
        </div>
      );
    }
  }
}
