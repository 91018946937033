import React from "react";
import { Table } from "reactstrap";
import "../../assets/styles/App.css";
import Sidebar from "./Sidebar";
import AppNavbar from "./AppNavbar";
import CustomModal from "./CustomModal";
import { backendBaseUrl } from "../../config";
import {
  getSites,
  getUser,
  createSite,
  editSite,
  deleteSite
} from "../../constants/endpoints";
import {
  getAuthenticatedData,
  postAuthenticatedData,
  putAuthenticatedData,
  deleteAuthenticatedData
} from "../../utils/async";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

// import { toast } from "react-toastify";
import SearchBar from "../SearchBar";
import styles from "../SearchBar/search.module.css";

export default class ServiceSites extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      userResults: getAuthenticatedData(backendBaseUrl + getUser),
      prevPage: null,
      nextPage: null,
      sites: null,
      suggestions: [],
      showAddSiteModal: false,
      addsitename: "",
      addsitedomain: "",
      addButtonDisabled: true,
      isAddLoaded: true,
      showEditSiteModal: false,
      editsitename: "",
      editsitedomain: "",
      editButtonDisabled: true,
      isEditLoaded: true,
      showDeleteSiteModal: false,
      deleteSiteName: "",
      deleteSiteId: 0,
      deleteButtonDisabled: false,
      isDeleteLoaded: true
    };
    this.handleClear = this.handleClear.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.openAddSiteModal = this.openAddSiteModal.bind(this);
    this.handleChangeAdd = this.handleChangeAdd.bind(this);
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.resetList = this.resetList.bind(this);
    this.closeAddModal = this.closeAddModal.bind(this);
    this.openEditSiteModal = this.openEditSiteModal.bind(this);
    this.handleChangeEdit = this.handleChangeEdit.bind(this);
    this.handleEditButtonClick = this.handleEditButtonClick.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);
    this.openDeleteSiteModal = this.openDeleteSiteModal.bind(this);
    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
  }

  componentDidMount() {
    getAuthenticatedData(
      backendBaseUrl +
        getSites +
        "?orgId=" +
        this?.props?.location?.state?.id +
        "&paginate=true"
    ).then(
      result => {
        this.setState({
          sites: result?.data,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
    // this.state.userResults.then(
    //   result => {
    //     this.setState({ isLoaded: true });
    //     if (result?.data?.roles?.includes("USER_ADMIN")) {
    //       this.resetList();
    //     }
    //   },
    //   () => {
    //     this.setState({ isLoaded: true });
    //   }
    // );
  }

  goToPreviousPage() {
    getAuthenticatedData(backendBaseUrl + this.state.prevPage).then(
      result => {
        this.setState({
          sites: result?.data,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  goToNextPage() {
    getAuthenticatedData(backendBaseUrl + this.state.nextPage).then(
      result => {
        this.setState({
          sites: result?.data,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  handleClear() {
    getAuthenticatedData(
      backendBaseUrl +
        getSites +
        "?orgId=" +
        this?.props?.location?.state?.id +
        "&paginate=true"
    ).then(
      result => {
        this.setState({
          sites: result?.data,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  handleSearch(value) {
    if (value) {
      getAuthenticatedData(
        backendBaseUrl +
          getSites +
          "?orgId=" +
          this?.props?.location?.state?.id +
          "&paginate=true&search=" +
          value
      ).then(
        result => {
          this.setState({
            sites: result?.data,
            isLoaded: true,
            prevPage: result?.paging?.prevUrl,
            nextPage: result?.paging?.nextUrl
          });
        },
        () => {
          this.setState({
            isLoaded: true
          });
        }
      );
    }
  }

  handleSearchChange() {}

  openAddSiteModal() {
    this.setState({ showAddSiteModal: true });
  }

  openEditSiteModal(item) {
    this.setState({
      showEditSiteModal: true,
      editsitedomain: item?.siteDomain,
      editsitename: item?.siteName,
      editSiteId: item?.siteId
    });
  }

  openDeleteSiteModal(item) {
    this.setState({
      showDeleteSiteModal: true,
      deleteSiteName: item?.siteName,
      deleteSiteId: item?.siteId
    });
  }

  handleChangeAdd(e) {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        this.setState({
          addButtonDisabled:
            this.state.addsitedomain === "" || this.state.addsitename === ""
        });
      }
    );
  }

  handleChangeEdit(e) {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        this.setState({
          editButtonDisabled:
            this.state.editsitedomain === "" || this.state.editsitename === ""
        });
      }
    );
  }

  handleAddButtonClick() {
    const data = {
      domain: this.state.addsitedomain,
      name: this.state.addsitename,
      isParentSite: false
    };
    this.setState({ isAddLoaded: false, addButtonDisabled: true });
    postAuthenticatedData(
      backendBaseUrl + createSite + "/" + this?.props?.location?.state?.id,
      data
    ).then(result => {
      this.setState({
        addsitedomain: "",
        addsitename: "",
        showAddSiteModal: false,
        isAddLoaded: true
      });
      if (result.status === 400) {
        toast.error("Name or Domain invalid.");
      } else if (result.status === 200) {
        toast.success("New site added successfully!");
        this.resetList();
      } else {
        toast.error("Something went wrong!");
      }
      return result.json();
    });
  }

  handleEditButtonClick() {
    const data = {
      domain: this.state.editsitedomain,
      name: this.state.editsitename,
      isParentSite: false
    };
    this.setState({ isEditLoaded: false, editButtonDisabled: true });
    putAuthenticatedData(
      backendBaseUrl + editSite + "/" + this.state.editSiteId,
      data
    ).then(result => {
      this.setState({
        editsitedomain: "",
        editsitename: "",
        showEditSiteModal: false,
        isEditLoaded: true
      });
      if (result.status === 400) {
        toast.error("Name or Domain invalid.");
      } else if (result.status === 200) {
        toast.success("Site edited successfully!");
        this.resetList();
      } else {
        toast.error("Something went wrong!");
      }
      return result.json();
    });
  }

  handleDeleteButtonClick() {
    this.setState({ isDeleteLoaded: false, deleteButtonDisabled: true });
    deleteAuthenticatedData(
      backendBaseUrl + deleteSite + "/" + this.state.deleteSiteId,
      {}
    ).then(result => {
      this.setState({
        showDeleteSiteModal: false,
        isDeleteLoaded: true,
        deleteButtonDisabled: false
      });
      if (result.status === 400) {
        toast.error(
          "Remove all users associated with this site before deleting."
        );
      } else if (result.status === 200) {
        toast.success("Site deleted successfully!");
        this.resetList();
      } else {
        toast.error("Something went wrong!");
      }
      return result.json();
    });
  }

  resetList() {
    getAuthenticatedData(
      backendBaseUrl +
        getSites +
        "?orgId=" +
        this?.props?.location?.state?.id +
        "&paginate=true"
    ).then(
      result => {
        this.setState({
          sites: result?.data,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  closeAddModal() {
    this.setState({ showAddSiteModal: false });
  }

  closeEditModal() {
    this.setState({ showEditSiteModal: false });
  }

  closeDeleteModal() {
    this.setState({ showDeleteSiteModal: false });
  }

  render() {
    const { isLoaded, sites } = this.state;
    if (!isLoaded) {
      return <div className="loading"></div>;
    } else {
      return (
        <div
          className={
            this.state.showPopup || this.state.showModal
              ? "prevent-scrolling"
              : ""
          }
        >
          <AppNavbar />
          <div className="row">
            <div className="col-md-2 sidebar">
              <Sidebar from="2" results={this.state.userResults} />
            </div>
            <div className="col-md-10 sidepadding">
              <div className="row rowmargin abovetable custom-flex-wrapper">
                <Link
                  to={{ pathname: "/service-dashboard" }}
                  className="back-link"
                >
                  &lt; Back
                </Link>
                <div>
                  <Button
                    className="addaccount"
                    onClick={this.openAddSiteModal}
                  >
                    Add Site
                  </Button>
                  {this.state.showPopup ? (
                    <CustomModal
                      text='Click "Close Button" to hide popup'
                      closePopup={this.togglePopup}
                      resetList={this.resetList}
                    />
                  ) : null}
                </div>
              </div>
              <SearchBar
                autoFocus
                shouldRenderClearButton
                shouldRenderSearchButton
                renderClearButton
                renderSearchButton
                placeholder="Search Sites"
                onChange={this.handleSearchChange}
                onClear={this.handleClear}
                onSearch={this.handleSearch}
                suggestions={this.state.suggestions}
                styles={styles}
              />
              {(() => {
                if (!sites || sites.length === 0) {
                  return (
                    <div className="table-error">
                      <p>There are no results to display</p>
                    </div>
                  );
                } else {
                  return (
                    <div className="table-container">
                      <Table className="tablebox">
                        <thead>
                          <tr>
                            <th>Site Name</th>
                            <th>Site Domain</th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {sites?.map?.(item => (
                            <tr key={item.siteId}>
                              <td id={"dName" + item.siteId}>
                                {item?.siteName}
                              </td>
                              <td id={"dName" + item.siteId}>
                                {item?.siteDomain}
                              </td>
                              <td className="tablemodal">
                                <a
                                  onClick={() => {
                                    this.openEditSiteModal(item);
                                  }}
                                >
                                  <span id={item.userId} className="editbtn">
                                    Edit
                                  </span>
                                </a>
                              </td>
                              <td className="tablemodal">
                                <a
                                  onClick={() => {
                                    this.openDeleteSiteModal(item);
                                  }}
                                >
                                  <span
                                    id={item.userId}
                                    className="editbtn delete-link"
                                  >
                                    Delete
                                  </span>
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div className="custom-pagination">
                        {(() => {
                          if (
                            this.state.prevPage === null ||
                            !this.state.prevPage
                          ) {
                            return <td></td>;
                          }
                          return (
                            <div
                              className="pagination-previous"
                              onClick={() => this.goToPreviousPage()}
                            >
                              <span>&lt; Previous</span>
                            </div>
                          );
                        })()}
                        {(() => {
                          if (
                            this.state.nextPage === null ||
                            !this.state.nextPage
                          ) {
                            return <td></td>;
                          }
                          return (
                            <div
                              className="pagination-next"
                              onClick={() => this.goToNextPage()}
                            >
                              <span>Next &gt;</span>
                            </div>
                          );
                        })()}
                      </div>
                    </div>
                  );
                }
              })()}
              {this.state.showAddSiteModal && (
                <div className="custommodal">
                  <div className="custommodaldeleteuser">
                    <form className="loginpad" onSubmit={this.handleSubmitEdit}>
                      <h6 className="modal-heading">Add Site</h6>
                      <Button
                        // eslint-disable-next-line react/prop-types
                        onClick={this.closeAddModal}
                        className="cancle"
                      >
                        {" "}
                        X{" "}
                      </Button>
                      <hr className="hrpadding" />
                      <FormGroup controlId="email" bsSize="large">
                        <div className="row">
                          <div className="col-md-3 formfont">Site Name</div>
                          <div className="col-md-9">
                            <FormControl
                              value={this.state.addsitename}
                              onChange={this.handleChangeAdd}
                              type="text"
                              name="addsitename"
                            />
                          </div>
                        </div>
                      </FormGroup>
                      <FormGroup controlId="email" bsSize="large">
                        <div className="row">
                          <div className="col-md-3 formfont">Site Domain</div>
                          <div className="col-md-9">
                            <FormControl
                              value={this.state.addsitedomain}
                              onChange={this.handleChangeAdd}
                              type="text"
                              name="addsitedomain"
                            />
                          </div>
                        </div>
                      </FormGroup>
                      <Button
                        onClick={this.handleAddButtonClick}
                        disabled={this.state.addButtonDisabled}
                        className={
                          "loginbtn2 edit-account-btn btn-primary fill edit-org-btn"
                        }
                      >
                        {!this.state.isAddLoaded ? (
                          <img
                            src={require("../../assets/images/button-loading.gif")}
                          />
                        ) : (
                          <>Add Site</>
                        )}
                      </Button>
                    </form>
                  </div>
                </div>
              )}
              {this.state.showEditSiteModal && (
                <div className="custommodal">
                  <div className="custommodaldeleteuser">
                    <form className="loginpad" onSubmit={this.handleSubmitEdit}>
                      <h6 className="modal-heading">Edit Site</h6>
                      <Button
                        // eslint-disable-next-line react/prop-types
                        onClick={this.closeEditModal}
                        className="cancle"
                      >
                        {" "}
                        X{" "}
                      </Button>
                      <hr className="hrpadding" />
                      <FormGroup controlId="email" bsSize="large">
                        <div className="row">
                          <div className="col-md-3 formfont">Site Name</div>
                          <div className="col-md-9">
                            <FormControl
                              value={this.state.editsitename}
                              onChange={this.handleChangeEdit}
                              type="text"
                              name="editsitename"
                            />
                          </div>
                        </div>
                      </FormGroup>
                      <FormGroup controlId="email" bsSize="large">
                        <div className="row">
                          <div className="col-md-3 formfont">Site Domain</div>
                          <div className="col-md-9">
                            <FormControl
                              value={this.state.editsitedomain}
                              onChange={this.handleChangeEdit}
                              type="text"
                              name="editsitedomain"
                            />
                          </div>
                        </div>
                      </FormGroup>
                      <Button
                        onClick={this.handleEditButtonClick}
                        disabled={this.state.editButtonDisabled}
                        className={
                          "loginbtn2 edit-account-btn btn-primary fill edit-org-btn"
                        }
                      >
                        {!this.state.isEditLoaded ? (
                          <img
                            src={require("../../assets/images/button-loading.gif")}
                          />
                        ) : (
                          <>Edit Site</>
                        )}
                      </Button>
                    </form>
                  </div>
                </div>
              )}
              {this.state.showDeleteSiteModal && (
                <div className="custommodal">
                  <div className="custommodaldeleteuser">
                    <form className="loginpad" onSubmit={this.handleSubmitEdit}>
                      <h6 className="modal-heading">Delete Site</h6>
                      <Button
                        // eslint-disable-next-line react/prop-types
                        onClick={this.closeDeleteModal}
                        className="cancle"
                      >
                        {" "}
                        X{" "}
                      </Button>
                      <hr className="hrpadding" />
                      <p className="delete-user-admin-text">
                        Are you sure you want to delete{" "}
                        {this.state.deleteSiteName}?
                      </p>
                      <Button
                        onClick={this.handleDeleteButtonClick}
                        disabled={this.state.deleteButtonDisabled}
                        className={"loginbtn2 edit-account-btn warning fill"}
                      >
                        {this.state.deleteButtonDisabled ? (
                          <img
                            src={require("../../assets/images/warning-button-loading.gif")}
                          />
                        ) : (
                          <>Delete</>
                        )}
                      </Button>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
}
