import React from "react";
import { Table } from "reactstrap";
import "../../assets/styles/App.css";
import Sidebar from "./Sidebar";
import AppNavbar from "./AppNavbar";
import CustomAddOrgModal from "./CustomAddOrgModal";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import { backendBaseUrl } from "../../config";
import {
  getAllOrgData,
  getUser,
  editOrg,
  deleteOrg
} from "../../constants/endpoints";
import {
  getAuthenticatedData,
  putAuthenticatedData,
  deleteAuthenticatedData
} from "../../utils/async";
import { Link } from "react-router-dom";
// import { toast } from "react-toastify";
import SearchBar from "../SearchBar";
import styles from "../SearchBar/search.module.css";
import { toast } from "react-toastify";

export default class ServiceDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      userResults: getAuthenticatedData(backendBaseUrl + getUser),
      prevPage: null,
      nextPage: null,
      organizations: null,
      suggestions: [],
      showAddOrganizationModal: false,
      showEditModal: false,
      currentId: 0,
      editorganizationname: "",
      editButtonDisabled: true,
      isEditLoaded: true,
      showDeleteModal: false,
      deleteOrgId: 0,
      deleteOrgName: "",
      deleteButtonDisabled: false
    };
    this.handleClear = this.handleClear.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.openAddOrganizationModal = this.openAddOrganizationModal.bind(this);
    this.toggleAddAdminAccountModal = this.toggleAddAdminAccountModal.bind(
      this
    );
    this.resetList = this.resetList.bind(this);
    this.openEditOrgModal = this.openEditOrgModal.bind(this);
    this.handleChangeEdit = this.handleChangeEdit.bind(this);
    this.handleEditButtonClick = this.handleEditButtonClick.bind(this);
    this.openDeleteOrgModal = this.openDeleteOrgModal.bind(this);
    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
  }

  componentDidMount() {
    getAuthenticatedData(
      backendBaseUrl + getAllOrgData + "?paginate=true"
    ).then(
      result => {
        this.setState({
          organizations: result?.data,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
    // this.state.userResults.then(
    //   result => {
    //     this.setState({ isLoaded: true });
    //     if (result?.data?.roles?.includes("USER_ADMIN")) {
    //       this.resetList();
    //     }
    //   },
    //   () => {
    //     this.setState({ isLoaded: true });
    //   }
    // );
  }

  goToPreviousPage() {
    getAuthenticatedData(backendBaseUrl + this.state.prevPage).then(
      result => {
        this.setState({
          organizations: result?.data,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  goToNextPage() {
    getAuthenticatedData(backendBaseUrl + this.state.nextPage).then(
      result => {
        this.setState({
          organizations: result?.data,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  handleClear() {
    getAuthenticatedData(
      backendBaseUrl + getAllOrgData + "?paginate=true"
    ).then(
      result => {
        this.setState({
          organizations: result?.data,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  handleSearch(value) {
    if (value) {
      getAuthenticatedData(
        backendBaseUrl + getAllOrgData + "?paginate=true&search=" + value
      ).then(
        result => {
          this.setState({
            organizations: result?.data,
            isLoaded: true,
            prevPage: result?.paging?.prevUrl,
            nextPage: result?.paging?.nextUrl
          });
        },
        () => {
          this.setState({
            isLoaded: true
          });
        }
      );
    }
  }

  handleSearchChange() {}

  resetList() {
    getAuthenticatedData(
      backendBaseUrl + getAllOrgData + "?paginate=true"
    ).then(
      result => {
        this.setState({
          organizations: result?.data,
          isLoaded: true,
          prevPage: result?.paging?.prevUrl,
          nextPage: result?.paging?.nextUrl
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  openAddOrganizationModal() {
    this.setState({ showAddOrganizationModal: true });
  }

  toggleAddAdminAccountModal() {
    this.setState({
      showAddOrganizationModal: !this.state.showAddOrganizationModal
    });
  }

  openEditOrgModal(item) {
    this.setState({
      currentId: item?.orgId,
      editorganizationname: item?.orgName,
      showEditModal: true
    });
  }

  handleChangeEdit(e) {
    this.setState({
      [e.target.name]: e.target.value,
      editButtonDisabled: false
    });
  }

  handleEditButtonClick() {
    this.setState({ editButtonDisabled: true, isEditLoaded: false });
    const data = {
      orgName: this.state.editorganizationname
    };
    putAuthenticatedData(
      backendBaseUrl + editOrg + "/" + this.state.currentId,
      data
    ).then(() => {
      this.setState({
        showEditModal: false,
        isEditLoaded: true
      });
      toast.success("The organization has been successfully edited.");
      this.resetList();
    });
  }

  openDeleteOrgModal(item) {
    this.setState({
      showDeleteModal: true,
      deleteOrgId: item?.orgId,
      deleteOrgName: item?.orgName
    });
  }

  handleDeleteButtonClick() {
    this.setState({ deleteButtonDisabled: true });
    deleteAuthenticatedData(
      backendBaseUrl + deleteOrg + "/" + this.state.deleteOrgId,
      {}
    ).then(result => {
      if (result.status === 200) {
        this.setState({ deleteButtonDisabled: false, showDeleteModal: false });
        toast.success("Organization successfully deleted.");
        this.resetList();
      } else {
        toast.error(
          "You can't delete this organization without deleting all associated sites."
        );
        this.setState({ deleteButtonDisabled: false, showDeleteModal: false });
        this.resetList();
      }
    });
  }

  closeEditModal() {
    this.setState({ showEditModal: false });
  }

  closeDeleteModal() {
    this.setState({ showDeleteModal: false });
  }

  render() {
    const { isLoaded, organizations } = this.state;
    if (!isLoaded) {
      return <div className="loading"></div>;
    } else {
      return (
        <div
          className={
            this.state.showPopup || this.state.showModal
              ? "prevent-scrolling"
              : ""
          }
        >
          <AppNavbar />
          <div className="row">
            <div className="col-md-2 sidebar">
              <Sidebar from="2" results={this.state.userResults} />
            </div>
            <div className="col-md-10 sidepadding">
              <div className="row rowmargin abovetable">
                <div className="addaccount-container">
                  <Button
                    className="addaccount"
                    onClick={this.openAddOrganizationModal}
                  >
                    Add Organization
                  </Button>
                  {this.state.showAddOrganizationModal ? (
                    <CustomAddOrgModal
                      text='Click "Close Button" to hide popup'
                      closePopup={this.toggleAddAdminAccountModal}
                      resetList={this.resetList}
                      makeAdmin={true}
                      orgId={this?.props?.location?.state?.id}
                    />
                  ) : null}
                </div>
              </div>
              <SearchBar
                autoFocus
                shouldRenderClearButton
                shouldRenderSearchButton
                renderClearButton
                renderSearchButton
                placeholder="Search Organizations"
                onChange={this.handleSearchChange}
                onClear={this.handleClear}
                onSearch={this.handleSearch}
                suggestions={this.state.suggestions}
                styles={styles}
              />
              {(() => {
                if (!organizations || organizations.length === 0) {
                  return (
                    <div className="table-error">
                      <p>There are no results to display</p>
                    </div>
                  );
                } else {
                  return (
                    <div className="table-container">
                      <Table className="tablebox">
                        <thead>
                          <tr>
                            <th>Organization</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {organizations?.map?.(item => (
                            <tr key={item.siteId}>
                              <td id={"dName" + item.orgId}>{item.orgName}</td>
                              <td className="tablemodal">
                                <a
                                  onClick={() => {
                                    this.openEditOrgModal(item);
                                  }}
                                >
                                  <span id={item.userId} className="editbtn">
                                    Edit
                                  </span>
                                </a>
                              </td>
                              <td className="tablemodal">
                                <Link
                                  to={{
                                    pathname: "/service-sites",
                                    state: {
                                      type: "site",
                                      id: item.orgId
                                    }
                                  }}
                                >
                                  <span id={item.userId} className="editbtn">
                                    View Sites
                                  </span>
                                </Link>
                              </td>
                              <td className="tablemodal">
                                <Link
                                  to={{
                                    pathname: "/service-admins",
                                    state: {
                                      id: item.orgId
                                    }
                                  }}
                                >
                                  <span id={item.userId} className="editbtn">
                                    View All Admins
                                  </span>
                                </Link>
                              </td>
                              <td className="tablemodal">
                                <a
                                  onClick={() => {
                                    this.openDeleteOrgModal(item);
                                  }}
                                >
                                  <span
                                    id={item.userId}
                                    className="editbtn delete-link"
                                  >
                                    Delete
                                  </span>
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div className="custom-pagination">
                        {(() => {
                          if (
                            this.state.prevPage === null ||
                            !this.state.prevPage
                          ) {
                            return <td></td>;
                          }
                          return (
                            <div
                              className="pagination-previous"
                              onClick={() => this.goToPreviousPage()}
                            >
                              <span>&lt; Previous</span>
                            </div>
                          );
                        })()}
                        {(() => {
                          if (
                            this.state.nextPage === null ||
                            !this.state.nextPage
                          ) {
                            return <td></td>;
                          }
                          return (
                            <div
                              className="pagination-next"
                              onClick={() => this.goToNextPage()}
                            >
                              <span>Next &gt;</span>
                            </div>
                          );
                        })()}
                      </div>
                    </div>
                  );
                }
              })()}
              {this.state.showEditModal && (
                <div className="custommodal">
                  <div className="custommodaldeleteuser">
                    <form className="loginpad" onSubmit={this.handleSubmitEdit}>
                      <h6 className="modal-heading">Edit Organization</h6>
                      <Button
                        // eslint-disable-next-line react/prop-types
                        onClick={this.closeEditModal}
                        className="cancle"
                      >
                        {" "}
                        X{" "}
                      </Button>
                      <hr className="hrpadding" />
                      <FormGroup controlId="email" bsSize="large">
                        <div className="row">
                          <div className="col-md-3 formfont">
                            Organization Name
                          </div>
                          <div className="col-md-9">
                            <FormControl
                              value={this.state.editorganizationname}
                              onChange={this.handleChangeEdit}
                              type="text"
                              name="editorganizationname"
                            />
                          </div>
                        </div>
                      </FormGroup>
                      <Button
                        onClick={this.handleEditButtonClick}
                        disabled={this.state.editButtonDisabled}
                        className={
                          "loginbtn2 edit-account-btn btn-primary fill edit-org-btn"
                        }
                      >
                        {!this.state.isEditLoaded ? (
                          <img
                            src={require("../../assets/images/button-loading.gif")}
                          />
                        ) : (
                          <>Edit</>
                        )}
                      </Button>
                    </form>
                  </div>
                </div>
              )}
              {this.state.showDeleteModal && (
                <div className="custommodal">
                  <div className="custommodaldeleteuser">
                    <form className="loginpad" onSubmit={this.handleSubmitEdit}>
                      <h6 className="modal-heading">Delete Organization</h6>
                      <Button
                        // eslint-disable-next-line react/prop-types
                        onClick={this.closeDeleteModal}
                        className="cancle"
                      >
                        {" "}
                        X{" "}
                      </Button>
                      <hr className="hrpadding" />
                      <p className="delete-user-admin-text">
                        Are you sure you want to delete{" "}
                        {this.state.deleteOrgName}?
                      </p>
                      <Button
                        onClick={this.handleDeleteButtonClick}
                        disabled={this.state.deleteButtonDisabled}
                        className={"loginbtn2 edit-account-btn warning fill"}
                      >
                        {this.state.deleteButtonDisabled ? (
                          <img
                            src={require("../../assets/images/warning-button-loading.gif")}
                          />
                        ) : (
                          <>Delete</>
                        )}
                      </Button>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
}
