import { apiToken } from "../config";

export const commonAuthenticatedHeaders = () => {
  return {
    Authorization: "Bearer " + sessionStorage.getItem("bearer"),
    clientTypeIdentificationToken: apiToken
  };
};
export const commonUnauthenticatedHeaders = () => {
  return { clientTypeIdentificationToken: apiToken };
};
