/* eslint-disable react/prop-types */
import React from "react";
import "../../assets/styles/App.css";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import { toast } from "react-toastify";
import { backendBaseUrl } from "../../config";
import { getSites, createOrgSiteAndUser } from "../../constants/endpoints";
import { getAuthenticatedData, postAuthenticatedData } from "../../utils/async";

class CustomAddOrgModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      organizationname: "",
      sitedomain: "",
      sitename: "",
      firstname: "",
      lastname: "",
      email: "",
      employeeid: "",
      isLoaded: true,
      disableFirstButton: true,
      disableSecondButton: true,
      activePage: 1
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onFirstPageProceed = this.onFirstPageProceed.bind(this);
    this.goToFirstPage = this.goToFirstPage.bind(this);
  }
  componentDidMount() {
    getAuthenticatedData(backendBaseUrl + getSites).then(
      result => {
        this.setState({
          sites: result?.data,
          site: result?.data[0]?.siteId,
          domain: result?.data[0]?.siteDomain
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }
  onChange(e) {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        this.setState({
          disableFirstButton:
            this.state.organizationname === "" ||
            this.state.sitedomain === "" ||
            this.state.sitename === "",
          disableSecondButton:
            this.state.firstname === "" ||
            this.state.lastname === "" ||
            this.state.email === "" ||
            this.state.employeeid === ""
        });
      }
    );
  }
  onFirstPageProceed() {
    this.setState({ activePage: 2 });
  }
  onSubmit() {
    var data = {
      orgInfo: {
        orgName: this.state.organizationname
      },
      siteInfo: {
        domain: this.state.sitedomain,
        name: this.state.sitename,
        isParentSite: true
      },
      userInfo: {
        employeeId: this.state.employeeid,
        email: this.state.email,
        firstName: this.state.firstname,
        lastName: this.state.lastname
      }
    };
    this.setState({ isLoaded: false, disableSecondButton: true });

    postAuthenticatedData(backendBaseUrl + createOrgSiteAndUser, data).then(
      result => {
        this.setState({ isLoaded: true });
        if (result.status === 400) {
          toast.error(
            "Check that the site domain and the email you entered match, and that all the fields have valid values"
          );
        } else if (result.status === 200) {
          toast.success("New organization added successfully!");
          this.setState({
            organizationname: "",
            sitedomain: "",
            sitename: "",
            firstname: "",
            lastname: "",
            email: "",
            employeeid: "",
            activePage: 1
          });
          this.props.closePopup();
          this.props.resetList();
        } else {
          toast.error("Something went wrong!");
        }
        return result.json();
      }
    );
  }

  handleSubmit(event) {
    event.preventDefault();
  }
  goToFirstPage() {
    this.setState({ activePage: 1 });
  }
  render() {
    return (
      <div className="custommodal">
        <div className="custommodalinner">
          {(() => {
            if (this.state.activePage === 2) {
              return (
                <a className="add-org-back" onClick={this.goToFirstPage}>
                  &lt; Back
                </a>
              );
            }
          })()}
          <form className="loginpad" onSubmit={this.handleSubmit}>
            <h6 className="modal-heading">Create Organization</h6>
            <Button
              // eslint-disable-next-line react/prop-types
              onClick={this.props.closePopup}
              className="cancle"
              type="submit"
            >
              {" "}
              x{" "}
            </Button>
            <hr className="hrpadding" />
            {(() => {
              if (this.state.activePage === 1) {
                return (
                  <div className="firstpage">
                    <FormGroup controlId="name" bsSize="medium">
                      <div className="row">
                        <div className="col-md-3 formfont">
                          Organization Name
                        </div>
                        <div className="col-md-9">
                          <FormControl
                            autoFocus
                            required
                            type="text"
                            name="organizationname"
                            value={this.state.organizationname}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </FormGroup>

                    <FormGroup controlId="email" bsSize="large">
                      <div className="row">
                        <div className="col-md-3 formfont">Site Domain</div>
                        <div className="col-md-9">
                          <FormControl
                            required
                            type="text"
                            name="sitedomain"
                            value={this.state.sitedomain}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </FormGroup>

                    <FormGroup controlId="email" bsSize="large">
                      <div className="row">
                        <div className="col-md-3 formfont">Site Name</div>
                        <div className="col-md-9">
                          <FormControl
                            required
                            type="text"
                            name="sitename"
                            value={this.state.sitename}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </FormGroup>

                    <Button
                      onClick={this.onFirstPageProceed}
                      className="loginbtn1 create-account-btn"
                      disabled={this.state.disableFirstButton}
                    >
                      {this.state.isLoaded ? (
                        "Proceed"
                      ) : (
                        <img
                          src={require("../../assets/images/button-loading.gif")}
                        />
                      )}
                    </Button>
                  </div>
                );
              } else {
                return (
                  <div className="firstpage">
                    <FormGroup controlId="name" bsSize="medium">
                      <div className="row">
                        <div className="col-md-3 formfont">First Name</div>
                        <div className="col-md-9">
                          <FormControl
                            autoFocus
                            required
                            type="text"
                            name="firstname"
                            value={this.state.firstname}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </FormGroup>

                    <FormGroup controlId="email" bsSize="large">
                      <div className="row">
                        <div className="col-md-3 formfont">Last Name</div>
                        <div className="col-md-9">
                          <FormControl
                            required
                            type="text"
                            name="lastname"
                            value={this.state.lastname}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </FormGroup>

                    <FormGroup controlId="email" bsSize="large">
                      <div className="row">
                        <div className="col-md-3 formfont">Email</div>
                        <div className="col-md-9">
                          <FormControl
                            required
                            type="text"
                            name="email"
                            value={this.state.email}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup controlId="email" bsSize="large">
                      <div className="row">
                        <div className="col-md-3 formfont">Employee ID</div>
                        <div className="col-md-9">
                          <FormControl
                            required
                            type="text"
                            name="employeeid"
                            value={this.state.employeeid}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </FormGroup>

                    <Button
                      onClick={this.onSubmit}
                      className="loginbtn1 create-account-btn"
                      disabled={this.state.disableSecondButton}
                    >
                      {this.state.isLoaded ? (
                        "Submit"
                      ) : (
                        <img
                          src={require("../../assets/images/button-loading.gif")}
                        />
                      )}
                    </Button>
                  </div>
                );
              }
            })()}
          </form>
        </div>
      </div>
    );
  }
}

export default CustomAddOrgModal;
